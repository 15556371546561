// const baseUrl = "http://62.106.95.171:8050/";
// const baseWS = "ws://62.106.95.171:8050/api";
const baseUrl = "https://emerald-token.com/";
const baseWS = "wss://emerald-token.com/websocket";


const getCaptcha = "api/auth/getCaptcha";
const generalsignin = "api/auth/generalsignin";
const signout = "api/auth/signout";
const sendSecondCode = "api/auth/sendSecondCode";
const checkcode = "api/auth/verifycode";
const refreshToken = "api/auth/refreshToken";
const me = "api/me/userstatus";
const meUrgent = "api/me/userstatusUrgent";
const isVerified = "api/me/isVerified"
const userProfile = "api/me/userProfile";
const updateProfile = "api/me/updateProfile";
const updatePassword = "api/me/updatePassword";
const acceptterms = "api/me/acceptterms";
const applyReagentCode = "api/me/applyReagentCode";
const getRules = "api/me/rules";
const addOrder = "api/order/addOrder";
const alloreders = "api/order/allorders";
const satisfyorder = "api/order/satisfyorder";
const fetchFee = "api/fee/fetchFee";
const uploadfile = "api/upload/uploadnewfile";
const getFileConfig = "api/upload/getFileConfig";
const alluserfiles = "api/upload/alluserfiles";
const chargeWallet = "api/balance/chargeWallet";
const getPaymentGateways = "api/balance/getPaymentGateways";
const sendMessage = "api/me/sendmessage";
const getHistory = "api/me/getstellarhistory";
const requestUpdateStellarHistory = "api/me/requestUpdateStellarHistory";
const getDashboardInfo = "api/me/getDashboardInfo";
const removeOrder = "api/order/removeOrder";
const getSellOrderTokenSum = "api/order/getSellOrderTokenSum";

const demandTransaction = "api/transaction/demandTransaction";
const cancelTransaction = "api/transaction/cancelTransaction";
const getTransactionList = "api/transaction/getTransactions";
const getBankTransactionInfo = "api/transaction/getBankTransactionInfo";

const paymentCallback = "payment/callback";

const newOrderRequest = "api/orderrequest/prepaRenewRequest";
const executeOrderRequest = "api/orderrequest/executeRequest";
const cancelOrderRequest = "api/orderrequest/cancelRequest";

const getPaymentData = "api/chart/getPaymentData";
const getOrderData = "api/chart/getOrderData";

const getUserNotifications = "api/notification/getUserNotifications";
const markNotificationAsRead = "api/notification/markNotificationAsRead";

const getUserMarkets = "api/market/getUserMarkets";
const getMarketRules = "api/market/getMarketRules";

const readWhitepaper = "api/userMarket/readWhitepaper";

const clubGetLatestUserRating = "api/club/getLatestUserRating";
const clubGetRatingHistory = "api/club/getRatingHistory";
const clubGetAvailableGifts = "api/club/getAvailableGifts";
const clubGetReceivedGifts = "api/club/getReceivedGifts";
const clubGetCoupons = "api/club/getCoupons";
const clubRequestGift = "api/club/requestGift";
const clubApplyCoupon = "api/club/applyCoupon";

const balanceHistoryByTxId = "api/balanceHistory/getHistoryByTxId";


const apilist = {
    baseUrl: baseUrl,
    baseWS: baseWS,
    generalsignin: generalsignin,
    getCaptcha: getCaptcha,
    signout: signout,
    sendSecondCode: sendSecondCode,
    checkcode: checkcode,
    refreshToken: refreshToken,
    me: me,
    meUrgent: meUrgent,
    isVerified: isVerified,
    userProfile: userProfile,
    updateProfile: updateProfile,
    updatePassword: updatePassword,
    acceptterms: acceptterms,
    applyReagentCode: applyReagentCode,
    getRules: getRules,
    addOrder: addOrder,
    alloreders: alloreders,
    satisfyorder: satisfyorder,
    fetchFee: fetchFee,
    uploadfile: uploadfile,
    getFileConfig: getFileConfig,
    alluserfiles: alluserfiles,
    chargeWallet: chargeWallet,
    getPaymentGateways: getPaymentGateways,
    sendMessage: sendMessage,
    getHistory: getHistory,
    requestUpdateStellarHistory: requestUpdateStellarHistory,
    getDashboardInfo: getDashboardInfo,
    removeOrder: removeOrder,
    getSellOrderTokenSum: getSellOrderTokenSum,

    paymentCallback: paymentCallback,
    demandTransaction: demandTransaction,
    cancelTransaction: cancelTransaction,
    getTransactionList: getTransactionList,
    getBankTransactionInfo: getBankTransactionInfo,

    newOrderRequest: newOrderRequest,
    executeOrderRequest: executeOrderRequest,
    cancelOrderRequest: cancelOrderRequest,

    getPaymentData: getPaymentData,
    getOrderData: getOrderData,

    getUserNotifications: getUserNotifications,
    markNotificationAsRead: markNotificationAsRead,

    getUserMarkets: getUserMarkets,
    getMarketRules: getMarketRules,
    
    readWhitepaper: readWhitepaper,

    clubGetLatestUserRating: clubGetLatestUserRating,
    clubGetRatingHistory: clubGetRatingHistory,
    clubGetAvailableGifts: clubGetAvailableGifts,
    clubGetReceivedGifts: clubGetReceivedGifts,
    clubGetCoupons: clubGetCoupons,
    clubRequestGift: clubRequestGift,
    clubApplyCoupon: clubApplyCoupon,

    balanceHistoryByTxId: balanceHistoryByTxId,
}

export default apilist;
