import config from './../config.json';

class LocalStorageService{
    LocalStorageKeys = {
        RamzBoomToken: "ramzboomtoken",
        HasPubKey: "hasPubKey",
        VerifiedByAdmin: "verifiedByAdmin",
        WaitForAdmin: "waitForAdmin",
        JWTToken: "JWTToken",
        ForgotSmsTimer: 'ForgotSmsTimer',
        LicensePlan: 'licensePlan',
        SuperAppEnabled: 'superAppEnabled',
        TailwindDefualtThemeMode: 'theme',
    }

    ClearStorage = () => {
        localStorage.removeItem(this.LocalStorageKeys.RamzBoomToken);
        localStorage.removeItem(this.LocalStorageKeys.HasPubKey);
        localStorage.removeItem(this.LocalStorageKeys.VerifiedByAdmin);
        localStorage.removeItem(this.LocalStorageKeys.WaitForAdmin);
        localStorage.removeItem(this.LocalStorageKeys.ForgotSmsTimer);
        localStorage.removeItem(this.LocalStorageKeys.LicensePlan);
        localStorage.removeItem(this.LocalStorageKeys.SuperAppEnabled);

    }

    fixAndApplyDefaultThemeMode = () => {
        let isDark = false;

        try{
            if( (config.theme?.mode ?? "") === "dark" 
                    && 
                (localStorage.getItem(this.LocalStorageKeys.TailwindDefualtThemeMode) ?? '') === '' ){
                localStorage.setItem(this.LocalStorageKeys.TailwindDefualtThemeMode, "dark");
                isDark = true;
            }
        }catch(ignore){}
        
        return isDark;
    }
}

export default new LocalStorageService();