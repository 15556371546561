import React, { lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { getAppVersion } from './helper/cacheHelper'

const Layout = lazy(() => import('./containers/Layout'))

const Login = lazy(() => import('./pages/login/Login'))
const CreateAccount = lazy(() => import('./pages/login/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/login/ForgotPassword'))

//todo: Temporal, use New postfix in url.
const LoginNew = lazy(() => import('./pages/login/LoginNew'))
const CreateAccountNew = lazy(() => import('./pages/login/CreateAccountNew'))
const ForgotPasswordNew = lazy(() => import('./pages/login/ForgotPasswordNew'))

const PaymentCallback = lazy(() => import('./pages/PaymentCallback'))

function App() {

  return (
    <>
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/bank/callback" component={PaymentCallback} />

            <Route path="/loginNew" component={LoginNew} />
            <Route path="/create-accountNew" component={CreateAccountNew} />
            <Route path="/forgot-passwordNew" component={ForgotPasswordNew} />

            {/* Place new routes over this */}
            <Route path="/app" component={Layout} />
            {/* If you have an index page, you can remothis Redirect */}
            <Redirect exact from="/" to={"/login?v="+getAppVersion()} />
          </Switch>
        </Router>
    </>
  )
}

export default App
