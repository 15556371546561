import React from 'react'

function WaitMsg(props, { children }) {
  return (
    <div className={"p-4 mb-4 text-center text-xl sm:text-2xl md:text-3xl font-semibold " + (props?.style?.color ? props?.style?.color:"text-green-600 dark:text-green-300 ")}>
      {props?.children ? props?.children : ' لطفا منتظر بمانید...'}
    </div>
  )
}

export default WaitMsg