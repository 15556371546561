export default {
  // Backdrop
  backdrop: {
    base:
      'backdrop-scrollable fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center',
  },  
  tableCell: {
    base: 'px-1 py-1 sm:px-4 sm:py-3',
  },

}

