import React from 'react'
import CustomProgressBarWithMsg from './Basic/CustomProgressBarWithMsg'

function ThemedSuspense() {
  return (
    <div style={{position: 'relative'}} className="w-full h-screen p-6 text-xl sm:text-2xl md:text-3xl font-semibold text-green-600 dark:text-green-300">
      <CustomProgressBarWithMsg style={{color:  'text-green-600 dark:text-green-300'}}/>
    </div>
  )
}

export default ThemedSuspense
