// import * as React from 'react';

// import { CircularProgress } from '@material-ui/core';
import { styled } from 'styled-components';
import React from 'react'
import { ProgressBarIcon } from '../../icons'
import WaitMsg from '../Typography/WaitMsg';

const StyledProgressBar = styled(ProgressBarIcon)(({ theme, ...props }) => ({
  ...theme

}));


const CustomProgressBarWithMsg = ({ props }) => {

  const parentdiv = {
    position: 'absolute',
    bottom: '50%',
    // right: '50%',
    // width: '100%',
  }


  return (

    <div style={parentdiv} className='w-full z-50'>
      <div className='w-full flex flex-col items-center justify-center'>
        <StyledProgressBar className='animated animate-spin' />
        <WaitMsg style={{color: (props?.style?.color ? props?.style?.color : 'text-purple-900 dark:text-purple-200')}}/>
      </div>
    </div>
  )
}

export default CustomProgressBarWithMsg; 
