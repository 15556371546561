import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import theme from './assets/windmill.theme'
import axios from 'axios';
import { CookiesProvider } from "react-cookie";
import apilist from './api'
import { getAppVersion } from './helper/cacheHelper'
import LocalStorageService from './services/LocalStorageService'

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

const shouldIgnoreForward = (err) => {
  let result = false;

  try{
    result = String(err?.request?.responseURL??'').includes('verifycode');
  }catch(ignore){}

  return result;
}

axios.defaults.withCredentials = true

// For GET requests
axios.interceptors.request.use(
   (req) => {
      return req;
   },
   (err) => {
     const originalRequest = err.config;
      // if( err?.response?.status === 401  ){
      //   if( !shouldIgnoreForward(err) ){
      //     window.location = '/login';
      //   }
      // }
      // return Promise.reject(err);
     if (err?.response?.status === 401 && originalRequest.url === (apilist.baseUrl +  apilist.refreshToken) ) {
       if (!shouldIgnoreForward(err)) {
         window.location = '/login?v='+getAppVersion();
       }
       return Promise.reject(err);
     } 
     if (err?.response?.status === 401 && !originalRequest._retry && !shouldIgnoreForward(err)) {
       originalRequest._retry = true;
       return axios.post(apilist.baseUrl + apilist.refreshToken, {}).then((res) => {
         if (res.status === 200)
           return axios(originalRequest);
       });
     }
     return Promise.reject(err);

   }
);

// For POST requests
axios.interceptors.response.use(
   (res) => {
      // Add configurations here
      return res;
   },
   (err) => {
    const originalRequest = err.config;
    // if( err?.response?.status === 401  ){
    //   if( !shouldIgnoreForward(err) ){
    //     window.location = '/login';
    //   }
    // }
    // return Promise.reject(err);
   if (err?.response?.status === 401 && originalRequest.url === (apilist.baseUrl +  apilist.refreshToken)) {
     if (!shouldIgnoreForward(err)) {
       window.location = '/login?v='+getAppVersion();
     }
     return Promise.reject(err);
   } 
   if (err?.response?.status === 401 && !originalRequest._retry && !shouldIgnoreForward(err)) {
     originalRequest._retry = true;
     return axios.post(apilist.baseUrl + apilist.refreshToken, {}).then((res) => {
       if (res.status === 200)
         return axios(originalRequest);
     });
   }
   return Promise.reject(err);
   }
);

axios.defaults.withCredentials = true

ReactDOM.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences theme={theme} 
                dark={LocalStorageService.fixAndApplyDefaultThemeMode()}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Windmill>
    </Suspense>
  </SidebarProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
